<template>
  <div class="orders">
    <el-descriptions class="mtt" title="订单信息" :column="1" :size="size" border>
      <el-descriptions-item label="订单状态">已完成</el-descriptions-item>
      <el-descriptions-item label="订单编号">{{ invoiceList.orderNum }}</el-descriptions-item>
      <el-descriptions-item label="下单时间">{{ invoiceItem.addTime }}</el-descriptions-item>
      <el-descriptions-item label="发票类型">{{ invoiceList.invoiceType == 0 ? '个人发票' : '企业发票'
        }}</el-descriptions-item>

    </el-descriptions>
    <el-descriptions style="margin-top: 20px;" class="mtt" title="发票信息" :column="1" :size="size" border>

      <el-descriptions-item label="发票状态">
        <span style="color: #2EDC28;" v-if="invoiceList.invoiceStatus == 0">开票完成</span>
        <span v-if="invoiceList.invoiceStatus == 1">开票失败</span>
        <span v-if="invoiceList.invoiceStatus == 2">待开票</span>
      </el-descriptions-item>
      <el-descriptions-item label="开票时间">{{ invoiceList.invoiceCreateTime }}</el-descriptions-item>
      <el-descriptions-item label="发票抬头">{{ invoiceList.invoiceHeard }}</el-descriptions-item>
      <el-descriptions-item label="开票金额">{{ invoiceList.invoicePrice }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>
<script>
import { getInfo } from "@/api/cookies";
import { OrderClient } from "@/api/orderClient";
const orderClient = new OrderClient();

export default {
  props: ["invoiceItem"],

  data() {
    return {
      activeName: "0",
      invoiceList: {}
    };
  },
  async created() {
    this.userInfo = getInfo();
    console.log(this.invoiceItem, 'invoiceItem');

    const res = await orderClient.getInvoiceStatusByOrderNum({
      orderNumber: this.invoiceItem.orderNumber,
    })
    this.invoiceList = res.data[0]
  },

  methods: {
    close() {
      this.$emit("isOK");
    },
  },
};
</script>
<style lang="less" scoped>
.el-button--primary {
  background-color: #409eff !important;
  border: none;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  color: #fff !important;
  font-weight: 400;
  letter-spacing: 2px;
}

.orders {
  padding: 20px !important;

}

/deep/ .el-dialog__header {
  border-bottom: 1px solid #DDDDDD;
}

.mtt {
  // margin-top: 20px;
}
</style>
<style lang="less">
.el-descriptions-row {
  .el-descriptions-item__cell:nth-child(1) {
    width: 100px;
  }
}

.el-descriptions__table {
  tbody:nth-child(2n-1) {
    background: #F9F9F9;
  }

  tbody:nth-child(2n) {
    background: #fff;

    .el-descriptions-item__cell {
      background: #fff;
    }
  }
}
</style>
