<template>
  <div class="orders">
    <div class="box">
      <div class="box_left">
        <div @click="tabClick(0)" :class="activeName == 0 ? 'tabsitem tchoose' : 'tabsitem'">个人发票</div>
        <div @click="tabClick(1)" :class="activeName == 1 ? 'tabsitem tchoose' : 'tabsitem'">企业发票</div>

      </div>
      <div class="box_right">
        <div class="br_til">电子普通发票信息</div>
        <el-form style="margin-top: 20px;width: 430px; padding-bottom: 20px" :model="ruleForm" :rules="rules"
          ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="发票价格：">
            <el-input disabled v-model="ruleForm.invoicePrice"></el-input>
          </el-form-item>
          <el-form-item label="订单编号：">
            <el-input disabled v-model="ruleForm.orderNumber"></el-input>
          </el-form-item>

          <el-form-item v-if="activeName == '0'" label="发票抬头：" prop="invoiceHeard">
            <el-input placeholder="请输入发票抬头" v-model="ruleForm.invoiceHeard"></el-input>
          </el-form-item>
          <el-form-item v-if="activeName == '1'" label="单位名称：" prop="unitName">
            <el-input placeholder="请输入单位名称" v-model="ruleForm.unitName"></el-input>
          </el-form-item>
          <el-form-item v-if="activeName == '1'" label="发票税号：" prop="invoiceTaxId">
            <el-input placeholder="请输入发票税号" v-model="ruleForm.invoiceTaxId"></el-input>
          </el-form-item>
          <el-form-item label="发票接收邮箱：" prop="email">
            <el-input placeholder="请输入发票接收邮箱" v-model="ruleForm.email"></el-input>
          </el-form-item>

          <el-form-item style="display: flex;justify-content: flex-end;">
            <el-button style="width: 100px;" plain @click="close()">取消</el-button>
            <el-button style="width: 100px;" type="danger" :disabled="isD"
              @click="submitForm('ruleForm')">提交</el-button>

          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- <el-tabs v-model="activeName">
      <el-tab-pane label="个人发票" name="0"></el-tab-pane>
      <el-tab-pane label="企业发票" name="1"></el-tab-pane>
    </el-tabs> -->

  </div>
</template>
<script>
import { getInfo } from "@/api/cookies";
import { OrderClient } from "@/api/orderClient";
const orderClient = new OrderClient();

export default {
  props: ["invoiceItem"],

  data() {
    return {
      activeName: "0",
      isD: false,
      ruleForm: {
        invoicePrice: "",
        orderNumber: "",
        invoiceHeard: "",
        unitName: "",
        invoiceTaxId: "",
        email: "",
      },
      rules: {
        invoiceHeard: [{ required: true, message: "请输入", trigger: "blur" }],
        unitName: [{ required: true, message: "请输入", trigger: "blur" }],
        invoiceTaxId: [{ required: true, message: "请输入", trigger: "blur" }],
        email: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  async created() {
    this.userInfo = getInfo();
    console.log(this.invoiceItem, "invoiceItem");
    this.ruleForm.orderNumber = this.invoiceItem.orderNumber;
    this.ruleForm.invoicePrice = this.invoiceItem.paidPrice;
  },

  methods: {
    tabClick(i) {
      this.activeName = i
    },
    close() {

      this.$emit("isOK");
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isD = true;
          const res = await orderClient.addInvoiceManageEntity({
            orderNumber: this.ruleForm.orderNumber,
            commodityType: this.invoiceItem.commodityType,
            orderId: this.invoiceItem.orderId,
            stuName: this.userInfo.userName,
            goodsName: this.invoiceItem.commodityName,
            userId: this.userInfo.id,
            invoiceType: this.activeName,
            invoiceUser: this.userInfo.mobile,
            email: this.ruleForm.email,
            unitName:
              this.activeName == "1" ? this.ruleForm.unitName : undefined,
            tenantid: this.userInfo.tenantId,
            orderState: this.invoiceItem.orderState,
            invoicePrice: this.ruleForm.invoicePrice,
            invoiceHeard:
              this.activeName == "0" ? this.ruleForm.invoiceHeard : undefined,
            invoiceTaxId:
              this.activeName == "1" ? this.ruleForm.invoiceTaxId : undefined,
          });
          if (res.code == 0) {
            this.isD = false;
            this.$emit("isOK");
          } else {
            this.isD = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-button--primary {
  background-color: #409eff !important;
  border: none;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  color: #fff !important;
  font-weight: 400;
  letter-spacing: 2px;
}

/deep/ .el-dialog__header {
  border-bottom: 1px solid #DDDDDD;
}

.orders {
  padding: 0 !important;

  .box {
    display: flex;

    .box_left {
      width: 141px;
      border-right: 1px solid #DDDDDD;
      display: flex;
      flex-direction: column;
      align-items: center;

      .tabsitem {
        margin-top: 20px;
        width: 100px;
        height: 36px;
        background: #FFF2F1;
        border-radius: 6px 6px 6px 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 14px;
        color: #FF7267;
        cursor: pointer;
      }

      .tchoose {
        background: #FF7267;
        color: #FFFDFD;
      }
    }

  }
}

.box_right {
  margin-left: 20px;
}

.br_til {

  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
  margin: 20px 0;
}
</style>
